@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');
@font-face {
    font-family: 'Colaborate-Thin';
    src:  url('../fonts/ColabThi.otf') format('opentype');
}
@import 'variables.scss';
@import 'mixins.scss';
@import 'global.scss';
@import 'ui.scss';
@import 'layout.scss';
@import 'pages.scss';
@import 'animation.scss';
@import 'helpers';