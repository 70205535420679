html {
    box-sizing: border-box;
    // scroll-behavior: smooth;
}
body{
    font-family: $font;
    color: $themeDark;
    overflow-x: hidden;
}
*, *:before, *:after {
    box-sizing: border-box;
}
body{
    margin: 0;
}
p, li, input, textarea, select, td{
    font-family: $font;
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;

}
p{
    margin-top:30px;
    margin-bottom: 30px;
    &:last-child{
        margin-bottom: 0;
    }
}
h1{
    margin-top:30px;
    margin-bottom: 30px;
    font-family: $themeFont;
    font-size: 32px;
    line-height: 33px;
    font-weight: 300;
    color: $themeBlack;
    letter-spacing: 2.88px;
}
h2{
    margin-top:30px;
    margin-bottom: 30px;
    font-family: $themeFont;
    font-size: 32px;
    line-height: 33px;
    font-weight: 300;
    color: $themeBlack;
    letter-spacing: 2.88px;
}
h3{
    margin-top:30px;
    margin-bottom: 30px;
    font-family: $themeFont;
    color: $themeBlack;
    font-size: 24px;
    line-height: 25px;
    font-weight: 300;
    letter-spacing: 1.8px;
}
h4{
    font-family: $font;
    font-size: 22px;
    line-height: 29px;
    font-weight: 400;
    letter-spacing: 0px;
}
a{
    text-decoration: none;
    color: inherit;
}
ul{
    padding-left: 0;
}
img{
    display: block;
    max-width: 100%;
}
figure{
    margin: 0;
}

input{
    &:focus-visible{
        outline: none;
    }
}

strong{
    font-weight: 900;
}