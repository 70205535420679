._top-hero-section{
    background-color: $themeDark;
    height: 500px;
    ._overlay-top{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(0, 0, 0, 0.3);
    }
    @media(min-width: $laptop){
        height: 100vh;
    }
}

._gallery{
    figure{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        img{
            display: block;
        }
    }
    @media(min-width: $tablet){
        figure{
            margin-bottom: 30px;
        }
    }
    @media(min-width: $laptop){
        figure{
            margin-bottom: 40px;
        }
    }
}

._sample-studios{
    ._column-6{
        max-width: unset;
    }
}

._section-catalogue{
    @include bottomPadding;
}

._partners-carousel-container{
    ._partners-item{
        max-width: 180px;
        padding: 30px 15px;
        img{
            width: 100%;
        }
        @media(min-width: $tablet){
            max-width: 180px;
        }
        @media(min-width: $laptop){
            max-width: 220px;
        }
    }
}

._section-shop-product{
    padding: 50px 0 0 0;
    ._left-column{
        width: 100%;
    }
    ._right-column{
        width: 100%;
    }
    ._display{
        margin-bottom: 15px;
        position: relative;
    }
    @media(min-width: $tablet){
        ._left-column{
            width: calc(50% - 20px);
        }
        ._right-column{
            width: calc(50% - 20px);
        }
    }
    @media(min-width: $laptop){
        ._display{
            margin-bottom: 0;
        }
    }
}

._product-actions{
    display: flex;
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;
    align-items: center;
    ._qty-label{
        margin: 0;
        color: $themeBlack;
    }
}

._section-you-may-like-heading{
    text-align: center;
    @include topPadding;
    ._hr{
        margin: 0 auto;
    }
    h2{
        margin-bottom: 0;
    }
}

._section-shopping-cart{
    padding-top: 60px; 
}

._cart-items-table{
    width: 100%;
    border-collapse: collapse;
    @media(max-width: 767px){
        thead{
            display: none;
        }
        td{
            position: relative;
            display: block;
            ._item-image{
                width: 100px;
                margin: 10px 0;
            }
            p{
                margin: 10px 0;
                text-align: right;
            }
            &:before{
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                color: $themeBlack;
            }
            &._remove{
                p{
                    position: absolute;
                    width: 100%;
                    button{
                        position: relative;
                        z-index: 1;
                    }
                }
            }
            &._product-name{
                &:before{
                    content: "PRODUCT";
                }
            }
            &._price{
                &:before{
                    content: "PRICE";
                }
            }
            &._quantity{
                display: flex;
                justify-content: flex-end;
                &:before{
                    content: "QUANTITY";
                }
            }
            &._subtotal{
                &:before{
                    content: "SUBTOTAL";
                }
                margin-bottom: 30px;
            }
        }
    }
    @media(min-width: $tablet){
        max-width: 800px;
        margin: 0 auto;
        // border-bottom: 2px solid #dddddd;
        th{
            color: $themeBlack;
            font-weight: 300;
            text-transform: uppercase;
            text-align: left;
            border-bottom: 2px solid #dddddd;
            padding: 0 0 15px;
            &:last-child{
                text-align: right;
            }
        }
        td{
            ._item-image{
                width: 100px;
                margin: 10px 0;
            }
            p{
                margin: 0;
            }
            &:last-child{
                text-align: right;
            }
        }
        tr{
            &:nth-of-type(1){
                td{
                    padding-top: 30px;
                }
            }
        }
    }
}

._section-cart-actions{
    ._flex-panel{
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 30px;
        row-gap: 30px;
        flex-wrap: wrap;
        border-bottom: 2px solid #dddddd;
        border-top: 2px solid #dddddd;
        margin-top: 10px;
        padding: 30px 0;
    }
    @media(min-width: $tablet){
        ._flex-panel{
            max-width: 800px;
            margin: 30px auto 0 auto;
        }
    }
}

._section-cart-totals{
    padding: 30px 0 0;
    ._flex-panel{
        max-width: 800px;
        margin: 0 auto;
        ._info{
            margin: 30px 0;
            p{
                margin: 0;
                font-size: 14px;
            }
        }
    }
}

._section-checkout{
    @include topBottomPadding;
    h4{
        font-size: 20px;
        color: $themeBlack;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 0;
    }
}

._checkout-table{
    width: 100%;
    margin:10px 0;
    border-collapse: collapse;
    th{
        text-align: left;
        padding: 0;
        color: $themeBlack;
        &:last-child{
            text-align: right;
        }
    }
    td{
        padding: 5px 0;
        &:last-child{
            text-align: right;
        }
    }
}

._checkout-table-cart-items{
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
    th{
        text-align: left;
        padding: 0;
        &:last-child{
            text-align: right;
        }
    }
    td{
        padding: 5px 0;
        &:last-child{
            text-align: right;
        }
    }
}

._cart-totals {
    margin: 20px 0;
    width: 100%;
    table{
        width: 100%;
    }
    td{
        color: $themeBlack;
        
        &:last-child{
            // text-align: right;
            white-space: nowrap;
        }
    }
    ._total td{
        color: $themeGold;
        font-weight: 600;
        font-size: 22px;
        text-transform: uppercase;
        padding: 10px 0 0;
    }
    @media(min-width: $tablet){
        max-width: 300px;
    }
    ._checkout-button{
        margin-top: 30px;
        width: 100%;
    }
}

._order-details {
    width: 100%;
    border-collapse: collapse;
    td{
        // border: 1px solid #ddd;
        color: $themeBlack;
        padding: 10px;
        &:nth-of-type(1){
            text-align: left;
            // font-style: italic;
            font-weight: 600;
            color: $themeDark;
            font-size: 14px;
        }
        &:last-child{
            text-align: left;
            white-space: nowrap;
            // font-style: italic;
        }
    }
    ._total td{
        color: $themeGold;
        font-weight: 600;
        font-size: 22px;
        text-transform: uppercase;
        padding: 10px 0 0;
    }
    // @media(min-width: $tablet){
    //     max-width: 300px;
    // }
    ._checkout-button{
        margin-top: 30px;
        width: 100%;
    }
}

._checkout-table-totals{
    margin: 15px 0;
    width: 100%;
    table{
        width: 100%;
    }
    td{
        color: $themeBlack;
        padding: 5px 0;
        &:last-child{
            text-align: right;
            white-space: nowrap;
        }
    }
    ._total td{
        color: $themeBlack;
        font-weight: 600;
        font-size: 22px;
        text-transform: uppercase;
        padding: 10px 0 0;
    }
    ._checkout-button{
        margin-top: 30px;
        width: 100%;
    }
}

._cart-qty-input{
    height: 40px;
    width: 60px;
    border: 1px solid #dddddd;
    color: $themeDark;
}

._checkout-submit{
    width: 100%;
}

._place-order{
    margin-bottom: 0;
}

._section-payment{
    text-align: center;
    @include topBottomPadding;
    ._cart-items-table{
        td{
            text-align: left;
            &:last-child{
                text-align: right;
            }
        }
    }
    ._flex-panel{
        display: flex;
        max-width: 800px;
        margin: 30px auto;
    }
    ._home-button{
        @include buttonStyle2;
    }
    ._panel{
        margin: 0 auto;
        max-width: 800px;
    }
    ._buttons{
        display: flex;
        column-gap: 20px;
        row-gap: 20px;
        flex-wrap: wrap;
    }
}

._section-account{
    @include topBottomPadding;
    h3{
        margin-top: 0;
    }
}

._cart-empty{
    text-align: center;
}

._visit-our-store-banner-section{
    position: relative;
    height: 100vh;
    max-height: 600px;
    background-position: center;
    background-size: cover;
    ._visit-our-store-block{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 690px;
        width: calc(100% - 40px);
        text-align: center;
        background-color: rgba(248, 247, 241, 0.8);
        padding: 30px;
        @media(min-width: $laptop){
            padding: 60px;
        }
    }
}

._order-details-billing-shipping{
    display: flex;
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;
    div{
        width: 100%;
    }
    @media(min-width: $tablet){
        column-gap: 0;
        div{
            width: 50%;
        }
    }
}