._center{
    text-align: center;
    *{
        margin-left:auto;
        margin-right:auto;
    }
}
._left{
    text-align: left;
}
._right{
    text-align: right;
}
._clearfix{
    clear: both;
}

._gold {
    color: $themeGold;
}

._padding-bottom{
    @include bottomPadding;
}

._padding-bottom-none{
    padding-bottom: 0;
}

._padding-top-none{
    padding-top:0;
}

._size{
    padding-top: 15px;
    padding-bottom: 15px; 
}

._widescreen{
    display: none;
    @media(min-width: $laptop){
        display: block;
    }
}

._light-background{
    background-color: $themeCreame;
    padding: 30px;
    @media(min-width: $laptop){
        padding: 50px;
    }
}