$font: 'Roboto', sans-serif;
$themeFont: 'Colaborate-Thin';

$themeGold: #D5CFC3;
$themeCreame: #F8F7F1;
$themeOcre: #E5E1D9;
$themeBlack: #333333;
$themeDark: #777777;
$themeGrey: #C3C3C3;
$themeOcreDarker: #af9f95;

$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;