._animate-in{
    visibility: hidden;
    opacity: 0;
    @include transition(0.75s);
    position: relative;
    top: 100px;
    &._animate-in-enter{
        visibility: visible;
        opacity: 1;
        top: 0;
    }
}

._animate-in-img{
    visibility: hidden;
    opacity: 0;
    @include transition(1s, .5s);
    position: relative;
    top: 0;
    // transform: scale(0.8);
    &._animate-in-img-enter{
        visibility: visible;
        opacity: 1;
        top: 0;
        transform: scale(1);
    }
}

@media(min-width: $laptop){
    ._fluid{
        &._odd{
            align-items: center;
            &:nth-of-type(odd){
                ._animate-in-img{
                    &:nth-of-type(1){
                        left: 100px;
                    } 
                    &._animate-in-img-enter{
                        left: 0;
                    }
                }
            }
            &:nth-of-type(even){
                ._animate-in-img{
                    &:nth-of-type(1){
                        right: 100px;
                    } 
                    &._animate-in-img-enter{
                        right: 0;
                    }
                }
            }
        }
        &._even{
            align-items: center;
            &:nth-of-type(odd){
                ._animate-in-img{
                    &:nth-of-type(1){
                        right: 100px;
                    } 
                    &._animate-in-img-enter{
                        right: 0;
                    }                    
                }
            }
            &:nth-of-type(even){
                ._animate-in-img{
                    &:nth-of-type(1){
                        left: 100px;
                    } 
                    &._animate-in-img-enter{
                        left: 0;
                    }
                }
            }
        }
    }
}