._grid{
    width: calc(100% - 40px);
    margin: 0 auto;
    max-width: 1260px;
    display: flex;
    column-gap: 20px;
    row-gap: 60px;
    flex-wrap: wrap;
    justify-content: flex-start;
    ._column{
        width: 100%;
        padding-left: 0!important;
        padding-right: 0!important;
    }
    &._form-grid{
        max-width: 800px;
    }
    &._form-grid-small{
        max-width: 400px;
    }
    &._grid-2{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
                padding-left: 0!important;
                padding-right: 0!important;
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(50% - 20px);
                padding-left: 0!important;
                padding-right: 0!important;
            }
        }
    }
    &._grid-3{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(33.33% - 27px);
            }
        }
    }
    &._grid-4{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(25% - 30px);
            }
        }
    }
    @media(min-width: $tablet){
        width: calc(100% - 80px);
        column-gap: 30px;
    }
    @media(min-width: $laptop){
        width: calc(100% - 80px);
        column-gap: 40px;
    }
}

._heading-1{
    margin-top:30px;
    margin-bottom: 30px;
    font-family: $themeFont;
    font-size: 32px;
    line-height: 33px;
    font-weight: 300;
    color: $themeBlack;
    letter-spacing: 2.88px;
}

._theme-color{
    color: $themeGold;
}

._theme-black{
    color: $themeBlack;
}

._seperator{
    height: 1px;
    background-color: #DDDDDD;
}

._hr{
    width: 70px;
    height: 6px;
    background-color: $themeGold;
    margin-bottom: 30px;
}
._hr-light{
    height: 1px;
    background-color: #ECEBE7;
    margin-bottom: 30px;
}
._hr-dark{
    height: 1px;
    background-color: $themeOcreDarker;
}

._button{
    @include buttonStyle;
    @include transition(0.1s);
    position: relative;
    top: 0;
    left: 0;
    font-weight: 300;
    @media (min-width: $laptop){
        &:hover{
            opacity: 0.9;
        }
    }
}

._button-submit{
    @include buttonStyle;
    @include transition(0.5s);
    position: relative;
    top: 0;
    left: 0;
    @media (min-width: $laptop){
        &:hover{
            opacity: 0.9;
        }
    }
    &._sending{
        left: 100vw;
    }
}

._column{
    width: 100%;
    @include columnPadding;
}

._column-6{
    @media (min-width: $tablet){
        width: 50%;
    }
}

._column-8{
    @media (min-width: $tablet){
        width: 66.666%;
    }
}

._box{
    @include boxLayout;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    ._column{
        max-width: 870px;
        h1, h2, h3{
            max-width: 720px;
        }
    }
    ._column-6{
        max-width: 435px;
    }
}

._box-2{
    @include boxLayout2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

._fluid{
    display: flex;
    flex-wrap: wrap;
    ._column{
        @include columnPadding2;
        width: 100%;
        p{        
            max-width: 1000px;
        }
        h1, h2, h3{
            max-width: 690px;
        }
    }
}
._inner{
    @include headerInner;
}
._section{
    position:relative;
}

._social-icons{
    display: flex;
    column-gap: 10px;
    margin: 7px 0 0 0;
    li{
        list-style: none;
        a{
            display: inline-block;
            @media(min-width: $laptop){
                position: relative;
                @include transition;
                top: 0;
                &:hover{
                    top: -3px;
                }
            }
        }
    }
}

._hamburger-icon{
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: transparent;
    border:none;
    cursor: pointer;
    div{
        width: 100%;
        height: 100%;
        position: relative;
        span{
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $themeGold;
            transform:translateY(-50%);
            left:0;
            @include transition;
            &:nth-of-type(1){
                top:37.5%;
            }
            &:nth-of-type(2){
                top:62.5%;
            }
        }
    }
    @media(min-width: $laptop){
        &:hover{
            div{
                span{                
                    &:nth-of-type(1){
                        top:30.5%;
                    }
                    &:nth-of-type(2){
                        top:70.5%;
                    }
                }
            }
        }
    }          
}

._close-icon{
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: transparent;
    border:none;
    cursor: pointer;
    div{
        width: 100%;
        height: 100%;
        position: relative;
        span{
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $themeGold;
            left:50%;
            top:50%;
            @include transition;
            &:nth-of-type(1){
                transform:translate(-50%, -50%) rotate(45deg);
            }
            &:nth-of-type(2){
                transform:translate(-50%, -50%) rotate(-45deg);
            }
        }
    }
    @media(min-width: $laptop){
        &:hover{
            span{
                width: 120%;
            }
        }
    }    
}

._section-template-1{
    @include topBottomPadding;
}

._section-template-blog{
    @include topBottomPadding;
    strong{
        color: $themeGold;
    }
}

._section-template-4{
    background-color: $themeCreame;
    @include topBottomPadding;
}

._section-template-2{
    background-color: $themeCreame;
    h2{
        margin-bottom: 10px;
    }
    h3{
        margin-top: 10px;
    }
    ._column{
        width: 100%;
        &:nth-of-type(1){
            height: 100vw;
            background-position: center;
            background-size: cover;
            ._overlay{
                display: none;
            }
        }
        &:nth-of-type(2){
            @include topBottomPadding;
        }
    }
    @media(min-width: $laptop){
        ._fluid{
            align-items: center;
            &._odd{
                &:nth-of-type(odd){
                    ._column{
                        &:nth-of-type(1){
                            order: 2;
                        } 
                    }
                }
            }
            &._even{
                &:nth-of-type(even){
                    ._column{
                        &:nth-of-type(1){
                            order: 2;
                        } 
                    }
                }
            }
        }
        ._column{
            width: 50%;
            &:nth-of-type(1){
                height: 50vw;
                position: relative;
                ._overlay{
                    display: block;
                    position: absolute;
                    width: 100%;
                    left:0;
                    top: 0;
                    height: 100%;
                    opacity: 0;
                    background-position: center;
                    background-size: cover;
                    @include transition;
                }
                &:hover{
                    ._overlay{
                        opacity: 1;
                    }
                }
            }
        }
        ._panel{
            max-width:600px;
            margin: 0 auto;
            max-height: calc(50vw - 200px);
            overflow-y: auto;
        }
    }
}

._section-template-3{
    background-color: $themeCreame;
    ._column{
        width: 100%;
        text-align: center;
        h3{
            margin: 0 auto 60px auto;
        }
        &:nth-of-type(1){
            height: 100vw;
            background-position: center;
            background-size: cover;
            padding-left: 0;
            padding-right:0;
            display: none;
        }
        &:nth-of-type(2){
            @include topBottomPadding;
        }
    }
    @media(min-width: $laptop){
        ._fluid{
            align-items: center;
            &:nth-of-type(odd){
                ._column{
                    &:nth-of-type(1){
                        order: 2;
                    } 
                }
            }
        }
        ._column{
            width: 50%;
            &:nth-of-type(1){
                height: 800px;
                display: block;
            }
        }
        ._panel{
            max-width:600px;
            margin: 0 auto;
            max-height: 600px;
        }
    }
    @media(min-width: $desktop){
        ._column{
            &:nth-of-type(1){
                height: 1000px;
            }
        }
        ._panel{
            max-height: 1000px;
        }
    }
}

._dropdown-btn {
    position: relative;
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 13px;
        right: 0px;
        border-right: 1.5px solid black; // Increased thickness
        border-bottom: 1.5px solid black; // Increased thickness
        transform: rotate(45deg);
        width: 12px; // Slightly larger arrow
        height: 12px; // Slightly larger arrow
        @include transition;
    }
    &._up {
        &:after {
            top: 17px;
            transform: rotate(-135deg);
        }
    }
}


._nav-link{
    > ul {
        height: 0px;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        @include transition(0.5s, 0.1s);
        &._show-list{
            height: 320px;
            opacity: 1;
            visibility: visible;
            a{
                color: $themeDark;
                font-size: 15px;
            }
        }
    }
}

._nav-link {
    a{
        // @include activeClassStyle;
    }    
}

._hero-item-container{
    position: relative;
    height: 500px;
    width: 100%;
    background-position: center;
    background-size: cover;
    // transform: translateZ(-1px) scale(2.03);
    ._msg{
        position: absolute;
        bottom: 0;
        padding: 30px 0;
        width: 100%;
        z-index: 1;
        ._heading{
            color: #fff;
            max-width: 472px;
            @media(min-width: $laptop){
                font-size: 32px;
                line-height: 33px;
                letter-spacing: 3.6px;
            }
        }        
    }
    @media(min-width: $laptop){
        height: 100vh;
        ._msg{
            // height: 200px;
            padding: 60px 0;
        }
    }
}

._hero-next-arrow{
    &.slick-next{
        right: 20px;
        &:before{
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(-45deg);
        }
        @media(min-width: $tablet){
            right: 30px;
        }
        @media(min-width: $laptop){
            right: 40px;
        }
        @media (min-width: 1340px){
            right: calc((100vw - 1260px) / 2)
        }
    }    
}

._slider-item-container{
    width: 100%;
    margin: 0 auto;
    max-width: 340px;
}

._slider_item-img{
    width: 100%;
    height: 100vw;
    background-position: center;
    background-size: cover;
    @media(min-width: $laptop){
        height: 800px;
    }
    @media(min-width: $desktop){
        height: 1000px;
    }
}

._flex-panel{
    width: 100%;
    img{
        width: 150px;
    }    
    @media(min-width: $tablet){
        display: flex;
        justify-content: space-between;
        img{
            margin: 0;
        }
    }    
}

._gallery-item{
    position: relative;
    margin: 0 15px;
    display: block;
    img{
        max-height: 100%;
        width: auto;
        display: block;
        max-width: 100vw;
    }
    ._caption{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        color: #fff;
        background-color: rgb(0, 0, 0);
        opacity: 0;
        padding: 25px 30px;
        text-align: center;
        font-weight: 300;
        font-size: 18px;
        letter-spacing: 0.1em;
        @include transition;
    }
    &:hover{
        ._caption{
            opacity: 0.7;
        }
    }
    @media (min-width: $laptop){
        height: 500px;
    }
}

._gallery-carousel-section{
    margin-bottom: 60px;
    @media(min-width: $laptop){
        margin-bottom: 100px;
    }
}

._gallery-next-arrow{
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% - 4px);
    width: 40px;
    background-color: rgba(255, 255, 255, 0.795);
    transform: inherit;
    @include transition;
    z-index: 1;
    &:before{
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 8px;
        width: 20px;
        height: 20px;
        border-right: 2px solid $themeBlack;
        border-bottom: 2px solid $themeBlack;
        transform: rotate(-45deg) translateY(-50%);
    }
    &:hover{
        background-color: rgba(255, 255, 255, 1);
    }
}

._gallery-prev-arrow{
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 4px);
    width: 40px;
    background-color: rgba(255, 255, 255, 0.795);
    transform: inherit;
    @include transition;
    z-index: 1;
    &:before{
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 8px;
        width: 20px;
        height: 20px;
        border-right: 2px solid $themeBlack;
        border-bottom: 2px solid $themeBlack;
        transform: rotate(135deg) translateY(-50%);
    }
    &:hover{
        background-color: rgba(255, 255, 255, 1);
    }
}

._tabs {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 0 20px 0;
    a{
        display: block;
        padding: 15px 10px;
        color: $themeGrey;
        letter-spacing: 1.44px;
        @include transition(.1s);
        &._active{
            @include activeClassStyle;
        }
        &:hover{
            color: $themeBlack;
        }
    }
}


._masonary-grid{
    column-count: 1;
    column-gap: 10px;
    img {
        max-width: 100%;
        display: block;
    }

    ._mg_col{
        margin: 0 0 40px 0;
        display: grid;
        grid-template-rows: 1fr auto;
        break-inside: avoid;
        color: black;
        text-decoration: none;
    }
    
    figure {
        background-position: center;
        background-size: cover;
        @media (min-width: $laptop){
            &._animation-image-swith{
                img{
                    @include transition;
                }
                &:hover{
                    img{
                        opacity: 0;
                    }
                }
            }
        }
    }
    
    figure > img {
        grid-row: 1 / -1;
        grid-column: 1;
        opacity: 1;
        @include transition;
    }
    
    figcaption {
        grid-row: 2;
        grid-column: 1;
        padding: 0;
        justify-self: start;
        width: 100%;
        font-size: 12px;
        z-index: 1;
        font-weight: 300;
        text-align: left;
        color: $themeDark;
        position: absolute;
        padding:  5px 0 0 0;
        background-color: #fff;
    }
    @media (min-width: $tablet){
        column-count: 2;
        column-gap: 20px;
    }
    @media (min-width: $laptop){
        column-count: 2;
        column-gap: 30px;
    }
}

._masonary-grid-cards{
    column-count: 1;
    column-gap: 10px;
    ._card {
        margin: 0 0 20px 0;;
        display: grid;
        grid-template-rows: 1fr auto;
        // margin-bottom: 10px;
        break-inside: avoid;
    }
    
    @media (min-width: $tablet){
        column-count: 2;
        column-gap: 20px;
    }
    @media (min-width: $laptop){
        column-count: 2;
        column-gap: 30px;
        ._card{
            margin-bottom: 30px;
        }
    }
}

._background-image-container{
    background-position: center;
    background-size: cover;
    position: relative;
}

._parallax{
    background-attachment: fixed;
}

._subscribe-button{
    border: none;
    background-color: transparent;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    font-size: 13px;
    &:after{
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 6px solid #fff;
        border-bottom: 5px solid transparent;
        position: absolute;
        right: -12px;
        top: 3px;
    }
}

._panel{
    .slick-dots{
        bottom: -50px;
    }
}

._card{
    background-color: $themeCreame;
    ._theme_heading{
        padding: 30px 30px 0 30px;
    }
    p{
        padding: 0 30px 0 30px;
    }
    figure{
        width: 100%;
        img{
            width: 100%;
        }
    }
}

._pagination{
    display: flex;
    justify-content: center;
    li{
        list-style: none;
        a{
            display: inline-block;
            padding: 10px;
            color: #CECECE;
            &._active{
                color: $themeGold;
            }
            &:hover{
                opacity: 0.8;
            }
        }
        &._prev, &._next{
            a{
                color: $themeDark;
            }
        }
    }
}

._form-row{
    // margin-top: 15px;
    // margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    :last-child{
        margin-bottom: 0;
    }
    @media(min-width: $laptop){
        flex-wrap: nowrap;
    }
}

._form-group{
    width: 100%;
    margin: 15px 0;
    label{
        color: $themeBlack;
        margin-bottom: 5px;
        display: block;
    }
}

._form-group-6{
    @media(min-width: $tablet){
        max-width: calc(50% - 10px);
    }
    @media(min-width: $laptop){
        max-width: calc(50% - 15px);
    }
}

._form-element{
    width: 100%;
    padding: 5px 7px;
    border-radius: 0;
    border: 1px solid #DDDDDD;
    background-color: transparent;
    min-height: 38px;
    color: $themeDark;
    font-size: 14px;
}

select._form-element{
    padding-top: 8px;
}

._form-group-checkbox{
    display:flex;
    margin: 15px 0;
    label{
        color: $themeBlack;
        margin-left: 5px;
        display: block;
    }
}

._error-group{
    margin: 10px 0;
}

._error-message{
    color: red;
}

._error{
    ._form-element{
        border: 1px solid red;
    }
}

._success-message{
    color: green;
}

._buttons-group{
    display: flex;
    align-items: center;
    column-gap: 10px;
}

._table-button{
    border-radius: 0;
    background-color: #fff;
    // height: 25px;
    padding: 7px;
    display: inline-block;
    line-height: 13px;
    border: none;
    opacity: 0.8;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    white-space: nowrap;
    &:hover{
        opacity: 1;
    }

    &._table-button-edit{
        background-color: green;
    }
    &._table-button-secondary{
        background-color: blue;
    }
    &._table-button-delete{
        background-color: red;
    }
}

._button-red{
    background-color: red;
    border: 1px solid red;
}

._blockquote{
    h2{
        color: #333333;
        font-size: 32px;
        line-height: 36px;
        font-weight: 100;
        font-family: $font;
    }
}


@media (min-width: $laptop){    
    ._animation-image-swith{
        color: black;
        text-decoration: none;
        background-position: center;
        background-size: cover;
        img{
            @include transition;
        }
        &:hover{
            img{
                opacity: 0;
            }
        }
    }
}

._ss_figure{
    figcaption{
        padding: 5px 0 30px 0;
        font-weight: 300;
        color: $themeDark;
        text-align: left;
        font-size: 12px;
    }
}

._social-media-sharing-section{
    ._sharing-panel{
        padding: 60px 30px;
        text-align: center;
        background-color: #F7F7F7;
        color: $themeBlack;
    }
}

._iframe-container{
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.3%;
    iframe, video{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}

#cookie{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $themeCreame;
    color: $themeBlack;
    z-index: 1;
}

._small-logo {
	svg{
        margin-top: 4px;
    }
}

._newsletter-subscription-form-everlytic{
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 504px;
    iframe{
        position: absolute;
        left: 0;
        top: 0;
    }
}

._terms-copy{
    text-align: left;
    p{
        display: flex;
        justify-content: flex-start;
        strong{
            margin-left: 0;
        }
        span{
            display: block;
            &:nth-of-type(1){
                width: 30px;
            }
            &:nth-of-type(2){
                width: 100%;
            }
        }
    }
}

.w_error-message{
    color: red;
}

._big-button-group{
    display: flex;
    column-gap: 30px;
    flex-wrap: wrap;
    row-gap: 30px;
}

._button-big{
    background-color: $themeOcre;
    padding: 30px 50px;
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    border-radius: 7px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    @media(min-width: $tablet){
        width: calc(50% - 30px);
    }
    @media(min-width: $laptop){
        width: auto;
        min-width: 280px;
    }
}

._figcapion {
    font-size: 12px;
	font-weight: 300;
	text-align: left;
	color: #777777;
	position: relative;
	top: 0;
	padding: 5px 0 0 0;
	background-color: #fff;
}

._public-breadcrums{
    font-weight: 300;
    padding: 30px 0;
}

._shop-banner{
    background-color: $themeCreame;
    padding: 30px;
    h1{
        text-transform: uppercase;
    }
    @media(min-width: $laptop){
        padding: 50px;
    }
}
  
._section-shop-list{
padding: 30px 0;
@media(min-width: $laptop){
    padding: 50px 0;
}
}

._shop-item{
    ._image{
        position: relative;
        padding-bottom: 100%;
        overflow: hidden;
        border: 1px solid #DDDDDD;
        ._inner{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            @include transition(.25s);
            
        }
    }
    @media(min-width: $laptop){
        &:hover{
            ._inner{
                transform: scale(1.1);
            }
        }
    }
    h3{
        margin-bottom: 10px;
    }
    p{
        margin: 0;
    }
    ._price{
        font-size: 20px;
        font-weight: 300;
        margin-top: 30px;
        color: $themeBlack;
    }
    ._flex{
        display: flex;
        justify-content: space-between;
        ._button{
            min-width: unset;
            white-space: nowrap;
            padding-left: 0;
            padding-right: 0;
            width: 100%;
            max-width: 140px;
        }
    }
}

._cart-icon{
    display: flex;
    align-items: center;
    span{
        color: $themeGold;
        margin-left: 3px;
        margin-top: 3px;
    }
}

._shop-product-gallery{
    @media(min-width: $laptop){
        position: sticky;
        top: 200px;
        display: flex;
        ._display{
            order: 2;
            width: calc(100% - 90px);
            margin-bottom: 0;
        }
        ._as-for-nav{
            width: 90px;
            max-height: 450px;
        }
        ._shop-gallery-side-images-item{
            width: 60px;
            padding-bottom: 60px;
        }
    }
    @media(min-width: $desktop){
        ._shop-gallery-side-images-item{
            width: 80px;
            padding-bottom: 80px;
        }
        ._as-for-nav{
            width: 120px;
        }
    }
}

._shop-gallery-image-display{
    width: 100%;
    padding-bottom: 82%;
    background-size: cover;
    background-position: center;
}

._as-for-nav{
    margin-right: -20px;
}

._shop-gallery-side-images-item{
    width: calc(19.3vw - 20px);
    padding-bottom: calc(19.3vw - 20px);
    border: 1px solid #fff;
    background-size: cover;
    background-position: center;
    @media(min-width: $tablet){
        width: calc(8.7vw - 20px);
        padding-bottom: calc(8.7vw - 20px);
    }
}

._shop-gallery-arrows{
    position: absolute;
    top: 86%;
    left: 20px;
    ._arrow{
        background-color: transparent;
        padding: 3px;
        cursor: pointer;
        border: none;
        span{
            border-right: 2px solid $themeBlack;
            border-top: 2px solid $themeBlack;
            width: 10px;
            height: 10px;
            display: block;
            opacity: .5;
            @media(min-width: $laptop){
                @include transition(.1s);
                &:hover{
                    opacity: .8;
                }
            }
        }
        &._arrow-prev{
            span{
                transform: rotate(-135deg);
            }
        }
        &._arrow-next{
            span{
                transform: rotate(45deg);
            }
        }
    }
}

._custom-quantity-input{
    display: flex;
    border: 1px solid #DDDDDD;
    height: 40px;
    width: 120px;
    > div{
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-right: 1px solid #DDDDDD;
        &:last-child{
            border-right: none;
        }
    }
    ._minus, ._plus{
        font-size: 20px;
        font-weight: 300;
    }
}

._section-simple-heading{
    text-align: center;
    background-color: $themeCreame;
    padding: 30px;
    h1, ._heading{
        text-transform: uppercase;
        margin: 0;
    }
    @media(min-width: $laptop){
        padding: 50px;
    }
}

._small-close-icon{
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    ._img{
        width: 15px;
    }
}

._clear-cart{
    @include buttonStyle2;
}

._coupon-form{
    max-width: 470px;
    width: 100%;
    display: flex;
    input{
        width: 66%;
        border:none;
        background-color: $themeCreame;
        height: 40px;
        padding-left: 8px;
        &::placeholder{
            color: $themeDark;
            font-style: italic;
            font-size: 14px;
        }
    }
    button{
        width: 33%;
        @include buttonStyle3;
    }
}

._checkbox-2{
    ._item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    ._checkbox-row{
        margin-top: 15px;
    }
    p{
        margin-top: 0;
    }
    label {
        display: flex;
        margin: auto;
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    
    input {
        position: absolute;
        transform: scale(0);
    }
    
    input:checked ~ ._checkbox {
        transform: rotate(45deg);
        width: 14px;
        height: 28px;
        margin: 0 7px 0 13px;
        border-color: $themeOcre;
        border-width: 3px;
        border-top-color: transparent;
        border-left-color: transparent;
        border-radius: 0;
        background-color: transparent;
    }
    
    ._checkbox {
        display: block;
        width: inherit;
        height: inherit;
        border: 1px solid #DDDDDD;
        // background-color: $themeOcre;
        border-radius: 0px;
        transition: all 0.2s cubic-bezier(0, 0.01, 0.23, 0.8);
    }     
}

._horline{
    width: 100%;
    height: 2px;
    background-color: #ddd;
}

._terms-messsage{
    a{
        text-decoration: underline;
    }
}


._customer-orders-table{
    text-align: left;
    width: 100%;
    border-collapse: collapse;
    @media(min-width: $tablet){
        th, td{
            &:last-child{
                text-align: right;
            }
        }
    }

    @media(max-width: $tablet){
        ._button{
            width: 100%;
        }
        td{
            &:last-child{
                padding-bottom: 30px;
            }
            &:nth-of-type(1){
                padding-top: 30px;
            }
        }
    }
    
    th{
        text-transform: uppercase;
        font-size: 14px;
        padding-bottom: 10px;
        @media(max-width: $tablet){
            display: none;
        }
    }
    td{
      padding: 10px 0;
      
      @media(max-width: $tablet){
        display: flex;
        justify-content: space-between;
        &:before{
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
        }
        &:nth-of-type(1){
            &:before{
                content: 'Order';
            }
        }
        &:nth-of-type(2){
            &:before{
                content: 'Date';
            }
        }
        &:nth-of-type(3){
            &:before{
                content: 'Status';
            }
        }
        &:nth-of-type(4){
            &:before{
                content: 'Total';
            }
        }
      }
    }
}

._cart-and-profile-icons{
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    img{
        max-width: 30px;
    }
}

._popup-message{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    @include transition(.25s);
    ._inner-panel{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        background-color: #fff;
        padding: 45px 30px;
        width: calc(100% - 60px);
        max-width: 600px;
        p{
            font-size: 20px;
            // font-weight: 400;
        }
    }
    visibility: visible;
    opacity: 1;
    &._hide{
        visibility: hidden;
        opacity: 0;
    }
    ._buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
        row-gap: 20px;
        flex-wrap: wrap;
    }
}

._popup-newsletter{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    @include transition(.25s);
    ._inner-panel{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        background-color: #fff;
        padding: 45px 30px;
        width: calc(100% - 60px);
        max-width: 900px;
        p{
            font-size: 20px;
            // font-weight: 400;
        }
    }
    visibility: visible;
    opacity: 1;
    &._hide{
        visibility: hidden;
        opacity: 0;
    }
    ._buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
        row-gap: 20px;
        flex-wrap: wrap;
    }
}

._callout{
    padding-left: 20px;
    border-left: 3px solid $themeGold;
}

._disclaimers{
    padding: 0 0 0 15px;
    margin: 0;
    max-width: 800px;
    margin: 0 auto 60px auto;
    border-left: 3px solid $themeGold;
    width: 100%;
    p{
        font-weight: 600;
        color: $themeBlack;
        margin: 12px 0;
        line-height: 20px;
    }
}

._divider-line{
    background-color: #ddd;
    height: 1px;
}

._color-box{
    display: none;
    @media(min-width: $laptop){
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 420px;
        background-color: $themeCreame;
        width: 100%;
        z-index: -1;
    }
}

._button-close {
    position: absolute;
    top: 30px;
    right: 30px;
}