._header{
    position: fixed;
    left:0;
    top: -100px;
    width:100%;
    height: 160px;
    z-index: 2;
    opacity: 0;
    background-color: #ffffff;
    @include transition(0.75s, 0.5s);
    ._logo{
        svg{
            width: 160px;
        }
    }
    @media (min-width: $laptop){
        top: -160px;
        height: 190px;
        ._logo{
            svg{
                width: 210px;
            }
        }
    }
}

._header._header-white{
    height: 90px;
}

._header._header-transparent{
    background-color: transparent;
}

._header._show{
    top: 0;
    opacity: 1;
}

._page-content{
    min-height: 65vh;
    &._padding-top{
        padding-top: 160px;
        @media (min-width: $laptop){
            padding-top: 190px;
        }
    }
}

._nav{
    position: fixed;
    left: 0;
    top: -200px;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    @include transition(0.75s, 0.1s);
    ._nav-header{
        height: 120px;
        ._logo{
            svg{
                width: 130px;
            }
        }
        @media (min-width: $laptop){
            height: 150px;
            ._logo{
                svg{
                    width: 150px;
                }
            }
        }
    }
    ._copyright{
        text-align: center;
        color: #DBDBDB;
        font-size: 12px;
        margin-top: 60px;
    }
}

._menu-open{
    ._nav{
        opacity: 1;
        visibility: visible;
        top: 0;
    }
}

._nav-links{
    width: auto;
    margin: 30px 0;
    li{
        text-align: center;
        list-style: none;
        a{
            display: inline-block;
            padding: 12px 20px;
            text-transform: uppercase;
            color: $themeBlack;
            font-size: 20px;
            line-height: 21px;
            letter-spacing: 1.8px;
            font-weight: 300;
            margin: 0 auto;
            @include transition;
            @media(min-width: $laptop){
                &:hover{
                    transform: scale(1.15);
                }
            }            
        }
    }
}

._footer{
    background-color: $themeOcre;
    color: #fff;
    ul{
        li{
            list-style: none;
            a{
                font-size: 14px;
            }
        }
    }
    p,h4,input{
        font-size: 14px;
    }
    ._box{
        flex-wrap: wrap;
        ._item{
            width: 100%;
            @include columnPadding;
            
        }
        &:nth-of-type(1){
            padding-top: 60px;
            padding-bottom: 30px;
        }
        &:nth-of-type(2){
            padding-bottom: 60px;   
            padding-top: 30px;      
            text-align: center; 
            ._item{
                padding-top: 15px;
                padding-bottom: 15px;
            }  
        }
    }
    ._social-icons{
        display: flex;
        justify-content: center;
    }
    ._logo{
        svg{
            width:210px;
            max-width: 100%;
        }
    }
    @media (min-width: $tablet) {
        ._box{
            justify-content: flex-start;
            &:nth-of-type(1){
                ._item{
                    width: 50%;
                }
            }            
        }
    }
    @media (min-width: $laptop) {
        a{
            @include transition(0.1s);
            &:hover{
                opacity: 0.8;
            }
           
        }

        ._box{
            &:nth-of-type(1){
                column-gap: 70px;
                flex-wrap: nowrap;
                padding-bottom: 20px;
                padding-top: 100px;
                ._item{
                    width: auto;
                }
            }
            &:nth-of-type(2){
                justify-content: space-between;
                align-items: flex-end;
                padding-bottom: 100px;
                padding-top: 0;
                ._item{
                    &:nth-of-type(1){
                        text-align: left;
                        width: 20%;
                        ._social-icons{
                            justify-content: flex-start;
                        }
                    }
                    &:nth-of-type(2){
                        order: 3;
                        width: 20%;
                        position: relative;
                        a{
                            position: absolute;
                            bottom: 22px;
                            left: 0;
                            width: 100%;
                        }
                    }
                    &:nth-of-type(3){
                        width: 40%;
                        min-width: 476px;
                    }
                }
            }
        }
    }
}

// admin
._dashboard-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
    ._menu-icon._close {
        ._hamburger-icon{
            div{
                span{       
                    @include transition;
                    &:nth-of-type(1){
                        top:50%;
                        transform:translateY(-50%) rotate(45deg);
                    }
                    &:nth-of-type(2){
                        top:50%;
                        transform:translateY(-50%) rotate(-45deg);
                    }
                }
            }         
        }
    }
    @media(min-width: $tablet){
        height: 70px;
    }
    @media(min-width: $laptop){
        height: 80px;
    }
    ._item{
        height: 100%;
    }
    a{
        display: inline-block;
    }
    ._dashboard-list{
        height: 100%;
        display: flex;
        margin: 0;
        align-items: center;
        p{
            margin-top: 0;
        }
        li{
            list-style: none;
            &._breadcrums{
                display: none;
                @media(min-width: $laptop){
                    display: flex;
                }
            }
            
        }
        > li{
            &:nth-of-type(1){
                padding: 10px 15px 10px 30px;
            }
            &:last-child{
                padding: 10px 30px 10px 15px;
            }
            
            @media (min-width:$tablet){
                &:nth-of-type(1){
                    padding: 10px 15px 10px 30px;
                }
                &:last-child{
                    padding: 10px 30px 10px 15px;
                }
            }
            @media (min-width:$laptop){
                &:nth-of-type(1){
                    padding: 10px 15px 10px 30px;
                }
                &:last-child{
                    padding: 10px 30px;
                }
            }
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            @media (min-width:$laptop){
                &._menu-icon{
                    display: none;
                }
            }
            > ul {
                position: absolute;
                top: 100%;
                right: 20px;
                a{
                    display: block;
                    padding: 7px 0;
                }
                background-color: $themeOcre;
                color: #fff;
                padding: 10px 20px;
                visibility: hidden;
                opacity: 0;
                @include transition;
                border-radius: 5px;
                min-width: 150px;
                > li{
                    padding: 0;
                    
                }
                &._show{
                    visibility: visible;
                    opacity: 1;
                }
                &:before{
                    content: "";
                    width: 15px;
                    height: 15px;
                    background-color: $themeOcre;
                    position: absolute;
                    top: -7px;
                    right: 14px;
                    transform: rotate(45deg);
                }
            }
        }
    }
    ._logo-li{
        @media (min-width: $laptop) {
            width: 200px;
            text-align: center;
            background-color: $themeBlack;
            a{
                margin: 0 auto;
            }
        }
    }
    ._logo{
        width: 60px;
        display: block;
        svg{
            width: 100%;
        }
        @media (min-width: $tablet){
            width: 70px;
        }
        @media (min-width: $laptop){
            width: 80px;
        }
    }
}

._dashboard-side-menu{
    position: fixed;
    top: 60px;
    left: -200px;
    color: #fff;
    width: 200px;
    bottom: 0;
    @include transition;
    z-index: 1;
    @media(min-width: $tablet){
        top: 70px;
        bottom: 0;
    }
    @media(min-width: $laptop){
        top: 80px;
        bottom: 0;
        left: 0;
    }
    ._overlay{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        bottom: 0;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        @include transition;
        cursor: pointer;
        @media(min-width: $laptop){
            display: none;
        }
    }
    &._show{
        left: 0;
        ._overlay{
            visibility: visible;
            opacity: 1;
        }
    }
    ._list{
        margin: 0;
        height: 100%;
        background-color: $themeOcre;
        overflow-y: auto;
        ul{
            background-color: $themeOcreDarker;
            border-bottom: 1px solid $themeOcre;
        }
        li{
            list-style: none;
            position: relative;
            font-size: 14px;
            
            a{
                display: block;
                padding: 7px 30px;
            }
            >ul{
                display: none;
                padding: 12px 0;
                a{
                    padding: 0 30px;
                }
                &._show{
                    display: block;
                }
            }
        }
        ._arrow{
            &:after{
                content: "";
                position: absolute;
                top: 14px;
                right: 30px;
                display: block;
                width: 7px;
                height: 7px;
                border-right: 1px solid #fff;
                border-bottom: 1px solid #fff;
                transform: rotate(45deg);
                @include transition;
            }
            &._up{
                &:after{
                    transform: rotate(-135deg);
                    top: 18px;
                }
            }
        }
    }
}

._dashboard-content{
    margin-top: 80px;
    width: 100%;
    @include transition;
    &._slide{
        margin-left: 200px;
    }
    @media(min-width: $tablet){
        margin-top: 90px;
    }
    @media(min-width: $laptop){
        margin-top: 100px;
        padding-left: 200px;
        &._slide{
            margin-left: 0;
        }
    }
    ._section{
        padding-left: 30px;
        padding-right: 30px;
    }
    ._button-submit{
        margin: 30px 0 100px 0;
    }
}

._section-header{
    ._breadcrumbs{
        font-size: 14px;
        color: $themeDark;
        @media(min-width: $laptop){
            display: none;
        }
        a{
            @include transition;
            color: $themeDark;
            &:hover{
                color: $themeBlack;
            }
        }
    }
    > h1{
        padding-bottom: 60px;
        padding-top: 20px;
        margin: 0;
    }
}

._form-dashboard{
    max-width: 650px;
    ._form-row{
        &:nth-of-type(1){
            margin-top: 0;
        }
    }
}

._left-actions-flexbox{
    display: flex;
    column-gap: 15px;
    align-items: center;
    ._cart-and-profile-icons{
        display: none;
        @media(min-width: $tablet){
            display: flex;
        }
    }
    ._cart-icon{
        margin-top: -2px;
    }
}


._section-account{
    @media(min-width: $tablet){
        ._flex-panel{
            display: flex;
            justify-content: space-between;
            max-width: 800px;
            margin: 0 auto;
        }
    }
}
._account-dashboard-nav{
    @media(min-width: $tablet){
        width: 150px;
    }
    ul{
        list-style: none;
        padding-left: 0;
        margin: 0;
        li{
            a{
                display: block;
                padding: 10px 0;
            }
        }
    }
}

._account-dashboard-display{
    margin-top: 30px;
    position: relative;
    @media(min-width: $tablet){
        margin-top: 10px;
        padding-left: 30px;
        width: calc(100% - 150px);
    }
    @media(min-width: $laptop){
        padding-left: 50px;
    }
}

._section-body{
    min-height: 500px;
}

._dashboard-footer{
    padding: 30px;
    text-align: center;
    border-top: 2px solid $themeCreame;
    p{
        margin: 0;
    }
}